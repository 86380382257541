<section>
  <app-processing-modal [process]="processing"></app-processing-modal>
  <app-error-modal [open]="error" [message]="aMessage"></app-error-modal>
  <app-success-modal [open]="success" [message]="aMessage"></app-success-modal>
</section>

<main>
  <div class="py-6 sm:px-6 lg:px-8">
    <h1 class="text-3xl font-bold leading-tight text-gray-900">
      Operators
    </h1>
    <section *ngIf="viewingOperators" class="max-screen-w-lg mx-auto">
      <div class="px-4 py-6 sm:px-0">
        <div class="w-full flex items-center justify-center">
          <div class="py-6 w-full max-w-screen-lg">
            <div class="flex items-start justify-between">
              <div>
                <h2 class="uppercase font-semibold leading-6 text-lg text-remit-blue-900">
                  Manage operators
                </h2>
                <p class="mt-1 text-sm leading-5 text-gray-600">
                  Operators registered under your account
                </p>
              </div>
              <div>
                <button type="button" (click)="viewingOperators=false; registeringOperator=true"
                  class="btn py-2  text-white bg-cool-gray-900 shadow hover:shadow-md">
                  New operator
                </button>
              </div>
            </div>
            <div *ngIf="!hasOperators" class="mt-10">
              <div class="flex flex-col items-center justify-center">
                <svg class="w-16 h-16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                    clip-rule="evenodd" />
                </svg>
                <div>
                  You don't have any operators registered under your account, yet.
                  <button (click)="viewingOperators=false; registeringOperator=true" class="underline">Add an
                    operator</button> instead
                </div>
              </div>
            </div>
            <div *ngIf="hasOperators">
              <form *ngIf="false" class="text-right">
                <div class="mt-4 max-w-md inline-grid grid-cols-2 grid-flow-row gap-x-1">
                  <div class="relative text-left">
                    <label for="search-by" class="block text-sm font-medium leading-5 text-gray-700">Search by</label>
                    <svg class="w-5 h-5 absolute z-10 right-0 mx-2 my-3 text-gray-500"
                      xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                      <path fill-rule="evenodd"
                        d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                        clip-rule="evenodd" />
                    </svg>
                    <select id="search-by" class="mt-1 py-2">
                      <option value="firstName">First name</option>
                      <option value="lastName">Last name</option>
                      <option value="username">Username</option>
                      <option value="role">Role</option>
                      <option value="enabled">Status</option>
                      <option value="email">Email</option>
                    </select>
                  </div>
                  <div class="text-left">
                    <label for="search_parameter" class="block text-sm font-medium leading-5 text-gray-700">
                      Search key</label>
                    <div class="mt-1 relative rounded-sm shadow-sm">
                      <input id="search_parameter" type="text" class="mt-1 py-2">
                      <div class="absolute inset-y-0 right-0 flex items-center">
                        <button type="submit" class="px-2 focus:outline-none">
                          <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                            stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              <div class="mt-4 flex flex-col">
                <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-sm">
                      <table class="min-w-full divide-y divide-gray-200">
                        <thead>
                          <tr>
                            <th
                              class="px-6 py-3 bg-cool-gray-900 text-left text-xs leading-4 font-medium text-white uppercase tracking-wider">
                              Name
                            </th>
                            <th
                              class="px-6 py-3 bg-cool-gray-900 text-left text-xs leading-4 font-medium text-white uppercase tracking-wider">
                              Contact
                            </th>
                            <th
                              class="px-6 py-3 bg-cool-gray-900 text-left text-xs leading-4 font-medium text-white uppercase tracking-wider">
                              Status
                            </th>
                            <th class="px-6 py-3 bg-cool-gray-900"></th>
                          </tr>
                        </thead>
                        <tbody class="bg-white divide-y divide-gray-200">
                          <tr *ngFor="let op of operators">
                            <td class="px-6 py-4 whitespace-no-wrap">
                              <div class="flex items-center">
                                <div
                                  class="flex-shrink-0 h-10 w-10 flex items-center justify-center overflow-hidden rounded-full">
                                  <img class="h-10 object-cover"
                                    src="https://st.depositphotos.com/2218212/2938/i/600/depositphotos_29387653-stock-photo-facebook-profile.jpg"
                                    [alt]="op.firstname">
                                </div>
                                <div class="ml-4">
                                  <div class="text-sm leading-5 font-medium text-gray-900">
                                    {{op.firstname}} {{op.lastname}}
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                              <div class="text-sm leading-5 text-gray-500">{{op.email}}</div>
                              <div class="text-sm leading-5 text-gray-500">{{op.mobile}}</div>
                            </td>
                            <td class="px-6 py-4 whitespace-no-wrap">
                              <span *ngIf="op.status.toLowerCase() === 'active'"
                                class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                Active
                              </span>
                              <span *ngIf="op.status.toLowerCase() !== 'active'"
                                class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-orange-100 text-orange-800">
                                Blocked
                              </span>
                            </td>
                            <td class="px-6 py-4 whitespace-no-wrap text-right text-sm leading-5 font-medium">
                              <button *ngIf="op.status.toLowerCase() !== 'active'" (click)="changeOperatorStatus(op)"
                                class="text-green-600 hover:text-green-900">Activate</button>
                              <button *ngIf="op.status.toLowerCase() === 'active'" (click)="changeOperatorStatus(op)"
                                class="text-orange-500 hover:text-orange-600">Block</button>
                              <button (click)="onEditOperator(op)"
                                class="ml-4 text-cool-gray-800 hover:text-cool-gray-900">Edit</button>
                              <button (click)="fsDialog=true; deletingOperator=true; operator=op "
                                class="ml-4 text-red-600 hover:text-red-900">Delete</button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div class="pt-4 flex items-center justify-center">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section *ngIf="registeringOperator" class="max-screen-w-lg mx-auto">
      <div class="px-4 py-6 sm:px-0">
        <div class="w-full flex items-center justify-center">
          <div class="py-6 w-full max-w-screen-lg">
            <div>
              <h2 class="uppercase font-semibold leading-6 text-lg text-remit-blue-900">
                Register operator
              </h2>
            </div>
            <form [formGroup]="registerOperatorForm" (ngSubmit)="registerOperator()">
              <div class="mt-8">
                <div>
                  <div>
                    <div class="px-4 sm:px-0">
                      <h3 class="text-lg font-medium leading-6 text-gray-900">Operator Information</h3>
                      <p class="mt-1 text-sm leading-5 text-gray-600">
                        Operator's personal information and sign in details.
                      </p>
                    </div>
                  </div>
                  <div class="mt-5 w-full">
                    <div>
                      <div class="shadow overflow-hidden sm:rounded-sm">
                        <div class="px-4 py-5 bg-white sm:p-6">
                          <div class="grid grid-cols-6 gap-6">
                            <div class="col-span-6 sm:col-span-3">
                              <label for="first_name" class="block text-sm font-medium leading-5 text-gray-700">First
                                name</label>
                              <input id="first_name" type="text" class="mt-1 py-2" formControlName="firstname">
                            </div>
                            <div class="col-span-6 sm:col-span-3">
                              <label for="last_name" class="block text-sm font-medium leading-5 text-gray-700">Last
                                name</label>
                              <input id="last_name" type="text" class="mt-1 py-2" formControlName="lastname">
                            </div>
                            <div class="col-span-6 sm:col-span-3">
                              <label for="email" class="block text-sm font-medium leading-5 text-gray-700">Email</label>
                              <input id="email" type="email" class="mt-1 py-2" formControlName="email">
                            </div>
                            <div class="col-span-6 sm:col-span-3">
                              <label for="mobile"
                                class="block text-sm font-medium leading-5 text-gray-700">Mobile</label>
                              <input id="mobile" type="tel" class="mt-1 py-2" formControlName="mobile">
                            </div>
                          </div>
                        </div>
                        <div class="px-4 py-3 bg-gray-50 flex items-center justify-between sm:px-6">
                          <div>
                            <button type="button" (click)="registeringOperator=false; viewingOperators=true"
                              class="btn py-2 bg-white shadow hover:shadow-md">
                              View operators
                            </button>
                          </div>
                          <div class="text-right">
                            <button type="submit" [disabled]="!registerOperatorForm.valid"
                              [class]="{'cursor-not-allowed': !registerOperatorForm.valid}"
                              class="btn py-2 text-white bg-cool-gray-900 shadow hover:shadow-md">
                              Register operator
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>

  <div *ngIf="fsDialog" class="absolute z-10 inset-0 overflow-y-auto">
    <div class="fixed inset-0 transition-opacity">
      <div class="absolute inset-0 bg-gray-500 opacity-75">
      </div>
      <button (click)="closeFsDialog()"
        class="absolute mt-20 mr-8 right-0 top-0 p-2 bg-white rounded-full cursor-pointer focus:outline-none">
        <svg class="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>
    </div>
    <div class="h-full flex items-center justify-center">
      <div
        class="w-full max-w-screen-md bg-white rounded-sm text-left overflow-hidden shadow-xl transform transition-all sm:my-8"
        role="dialog" aria-modal="true" aria-labelledby="modal-headline">
        <section *ngIf="editingOperator">
          <form [formGroup]="editOperatorForm" (ngSubmit)="updateOperator()" class="w-full">
            <div>
              <div>
                <div class="px-4 pt-4 sm:px-6 sm:pt-6">
                  <h3 class="text-lg font-medium leading-6 text-gray-900">Edit User</h3>
                  <p class="mt-1 text-sm leading-5 text-gray-600">
                    Edit operator's information.
                  </p>
                </div>
              </div>
              <div class="mt-5 w-full">
                <div>
                  <div class="overflow-hidden sm:rounded-sm">
                    <div class="px-4 py-5 bg-white sm:p-6">
                      <div class="grid grid-cols-6 gap-6">
                        <div class="col-span-6 sm:col-span-3">
                          <label for="first_name" class="block text-sm font-medium leading-5 text-gray-700">First
                            name</label>
                          <input id="first_name" type="text" class="mt-1 py-2" formControlName="firstname">
                        </div>
                        <div class="col-span-6 sm:col-span-3">
                          <label for="last_name" class="block text-sm font-medium leading-5 text-gray-700">Last
                            name</label>
                          <input id="last_name" type="text" class="mt-1 py-2" formControlName="lastname">
                        </div>
                        <div class="col-span-6 sm:col-span-3">
                          <label for="email" class="block text-sm font-medium leading-5 text-gray-700">Email</label>
                          <input id="email" type="email" class="mt-1 py-2" formControlName="email">
                        </div>
                        <div class="col-span-6 sm:col-span-3">
                          <label for="mobile" class="block text-sm font-medium leading-5 text-gray-700">Mobile</label>
                          <input id="mobile" type="tel" class="mt-1 py-2" formControlName="mobile">
                        </div>
                      </div>
                    </div>
                    <div class="px-4 py-3 bg-gray-50 flex items-center justify-between sm:px-6">
                      <div>
                        <button type="button" (click)="closeFsDialog()"
                          class="btn py-2 text-cool-gray-500 bg-white shadow hover:shadow-md">
                          Cancel
                        </button>
                      </div>
                      <div class="text-right">
                        <button type="submit" class="btn py-2 text-white bg-cool-gray-900 shadow hover:shadow-md">
                          Save changes
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </section>
        <section *ngIf="deletingOperator">
          <div class="px-4 pt-4 flex flex-start sm:px-6 sm:pt-6">
            <div
              class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
              <svg class="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
              </svg>
            </div>
            <div class="ml-4">
              <h3 class="text-lg font-medium leading-6 text-gray-900">Delete Operator</h3>
              <p class="mt-1 text-sm leading-5 text-gray-600">
                You're about to delete operator {{operator.firstname}} {{operator.lastname}}. This action cannot be
                undone!
              </p>
            </div>
          </div>
          <div class="mt-5 w-full">
            <div class="overflow-hidden sm:rounded-sm">
              <div class="px-4 py-3 bg-gray-50 flex items-center justify-between sm:px-6">
                <div>
                  <button type="button" (click)="closeFsDialog()"
                    class="btn py-2 text-cool-gray-500 bg-white shadow hover:shadow-md">
                    Cancel
                  </button>
                </div>
                <div class="text-right">
                  <button type="button" (click)="deleteOperator()"
                    class="btn py-2 text-white bg-red-600 shadow hover:shadow-md">
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</main>