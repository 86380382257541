<section>
  <app-processing-modal [process]="processing"></app-processing-modal>
  <app-error-modal [open]="error" [title]="aTitle" [message]="aMessage"></app-error-modal>
</section>

<main>
  <div class="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
    <div class="max-w-md w-full space-y-8">
      <div align="center">
        <img src="../../../assets/images/metbank-logo.png" alt="Logo">
        <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Sign in to your account
        </h2>
      </div>
      <form [formGroup]="form" (ngSubmit)="login()" class="mt-8 space-y-6">
        <div class="rounded-md shadow-sm space-y-1">
          <div>
            <label for="username" class="sr-only"></label>
            <input id="username" name="username" type="text" autocomplete="username" class="py-3" placeholder="Username"
              formControlName="username">
          </div>
          <div class="relative">
            <label for="password" class="sr-only">Password</label>
            <svg *ngIf="showPassword" (click)="showPassword=!showPassword"
              class="w-5 h-5 absolute z-20 right-0 mx-2 my-3 text-gray-500 cursor-pointer"
              xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
              <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
              <path fill-rule="evenodd"
                d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                clip-rule="evenodd" />
            </svg>
            <svg *ngIf="!showPassword" (click)="showPassword=!showPassword"
              class="w-5 h-5 absolute z-20 right-0 mx-2 my-3 text-gray-500 cursor-pointer"
              xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd"
                d="M3.707 2.293a1 1 0 00-1.414 1.414l14 14a1 1 0 001.414-1.414l-1.473-1.473A10.014 10.014 0 0019.542 10C18.268 5.943 14.478 3 10 3a9.958 9.958 0 00-4.512 1.074l-1.78-1.781zm4.261 4.26l1.514 1.515a2.003 2.003 0 012.45 2.45l1.514 1.514a4 4 0 00-5.478-5.478z"
                clip-rule="evenodd" />
              <path
                d="M12.454 16.697L9.75 13.992a4 4 0 01-3.742-3.741L2.335 6.578A9.98 9.98 0 00.458 10c1.274 4.057 5.065 7 9.542 7 .847 0 1.669-.105 2.454-.303z" />
            </svg>
            <input id="password" name="password" [type]="showPassword ? 'text' : 'password'"
              autocomplete="current-password" class="py-3" placeholder="Password" formControlName="password">
          </div>
        </div>
        <div class="flex items-center justify-center">
          <div class="text-sm">
            <a routerLink="/reset-password" class="font-medium text-cool-gray-600 hover:text-cool-gray-900">
              Forgot your password?
            </a>
          </div>
        </div>
        <div>
          <button type="submit" [disabled]="!form.valid" [class]="{'cursor-not-allowed': !form.valid}"
            class="btn py-4 text-white bg-cool-gray-900 shadow hover:shadow-md">
            Sign in
          </button>
        </div>
      </form>
    </div>
  </div>
</main>