<section>
  <app-processing-modal [process]="processing"></app-processing-modal>
  <app-error-modal [open]="error" [message]="aMessage"></app-error-modal>
  <app-success-modal [open]="success" [message]="aMessage"></app-success-modal>
</section>

<main>
  <section class="max-screen-w-lg mx-auto">
    <div class="px-4 py-6 sm:px-0">
      <div class="w-full flex items-center justify-center">
        <div class="py-6 w-full max-w-screen-lg">
          <div>
            <h2 class="uppercase font-semibold leading-6 text-lg text-remit-blue-900">
              Wallet to Bank Transfer
            </h2>
          </div>
          <form [formGroup]="transactionForm" (ngSubmit)="fsDialog=true">
            <div class="mt-8">
              <div>
                <div>
                  <div class="px-4 sm:px-0">
                    <h3 class="text-lg font-medium leading-6 text-gray-900">Transaction Information</h3>
                    <p class="mt-1 text-sm leading-5 text-gray-600">
                      Information about the transaction
                    </p>
                  </div>
                </div>
                <div class="mt-5 w-full">
                  <div>
                    <div class="shadow overflow-hidden sm:rounded-sm">
                      <div class="px-4 py-5 bg-white sm:p-6">
                        <div class="grid grid-cols-6 gap-6">
                          <div class="sm:col-span-3">
                            <label for="reference"
                              class="block text-sm font-medium leading-5 text-gray-700">Reference</label>
                            <input id="reference" type="text" class="mt-1 py-2" formControlName="reference">
                          </div>
                          <div class="relative sm:col-span-3">
                            <label for="type" class="block text-sm font-medium leading-5 text-gray-700">Type</label>
                            <svg class="w-5 h-5 absolute z-10 right-0 mx-2 my-3 text-gray-500"
                              xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                              <path fill-rule="evenodd"
                                d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                                clip-rule="evenodd" />
                            </svg>
                            <select id="type" class="mt-1 py-2" formControlName="type">
                              <option [value]="transactionCode">Wallet to Bank Transfer</option>
                            </select>
                          </div>
                          <div class="sm:col-span-1">
                            <label for="amount" class="block text-sm font-medium leading-5 text-gray-700">Amount</label>
                            <input id="amount" type="tel" class="mt-1 py-2" formControlName="amount">
                          </div>
                          <div class="relative sm:col-span-2">
                            <label for="bank-id" class="block text-sm font-medium leading-5 text-gray-700">Bank</label>
                            <svg class="w-5 h-5 absolute z-10 right-0 mx-2 my-3 text-gray-500"
                              xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                              <path fill-rule="evenodd"
                                d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                                clip-rule="evenodd" />
                            </svg>
                            <select id="bank-id" class="mt-1 py-2" aria-placeholder="Select bank"
                              formControlName="bankId">
                              <option [value]="null" disabled hidden>Select Bank</option>
                              <option *ngFor="let bank of banks" [value]="bank.code">{{bank.shortName}}</option>
                            </select>
                          </div>
                          <div class="sm:col-span-3">
                            <label for="amount" class="block text-sm font-medium leading-5 text-gray-700">Bank
                              Account</label>
                            <input id="amount" type="tel" class="mt-1 py-2" formControlName="account">
                          </div>
                        </div>
                      </div>
                      <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
                        <div class="inline-flex">
                          <button type="submit" [disabled]="!transactionForm.valid"
                            [class]="{'cursor-not-allowed': !transactionForm.valid}"
                            class="btn py-2 text-white bg-cool-gray-900 shadow hover:shadow-md">
                            Initiate transfer
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
  <div *ngIf="fsDialog" class="absolute z-10 inset-0 overflow-y-auto">
    <div class="fixed inset-0 transition-opacity">
      <div class="absolute inset-0 bg-gray-500 opacity-75">
      </div>
      <button (click)="fsDialog=false"
        class="absolute mt-20 mr-8 right-0 top-0 p-2 bg-white rounded-full cursor-pointer focus:outline-none">
        <svg class="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>
    </div>
    <div class="h-full flex items-center justify-center">
      <div
        class="w-full max-w-screen-md bg-white rounded-sm text-left overflow-hidden shadow-xl transform transition-all sm:my-8"
        role="dialog" aria-modal="true" aria-labelledby="modal-headline">
        <form [formGroup]="authForm" (ngSubmit)="walletTransfer()" class="w-full">
          <div>
            <div>
              <div class="px-4 pt-4 sm:px-6 sm:pt-6">
                <h3 class="text-lg font-medium leading-6 text-gray-900">Authorise Transcation</h3>
                <p class="mt-1 text-sm leading-5 text-gray-600">
                  Enter your operator code to authorise this transaction.
                </p>
              </div>
            </div>
            <div class="mt-5 w-full">
              <div>
                <div class="overflow-hidden sm:rounded-sm">
                  <div class="px-4 py-5 bg-white sm:p-6">
                    <div class="grid grid-cols-6 gap-6">
                      <div class="col-span-6 sm:col-span-3">
                        <label for="operator-code" class="block text-sm font-medium leading-5 text-gray-700">Operator
                          code</label>
                        <input id="operator-code" type="password" class="mt-1 py-2" formControlName="code">
                      </div>
                    </div>
                  </div>
                  <div class="px-4 py-3 bg-gray-50 flex items-center justify-between sm:px-6">
                    <div>
                      <button type="button" (click)="fsDialog=false"
                        class="btn py-2 text-cool-gray-500 bg-white shadow hover:shadow-md">
                        Cancel
                      </button>
                    </div>
                    <div class="text-right">
                      <button type="submit" [disabled]="!authForm.valid"
                        [class]="{'cursor-not-allowed': !authForm.valid}"
                        class="btn py-2 text-white bg-cool-gray-900 shadow hover:shadow-md">
                        Authorise
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</main>