<section>
  <app-processing-modal [process]="processing"></app-processing-modal>
  <app-error-modal [open]="error" [message]="aMessage"></app-error-modal>
  <app-success-modal [open]="success" [message]="aMessage"></app-success-modal>
  <app-warning-modal [open]="warning" [title]="aTitle" [message]="aMessage"></app-warning-modal>
</section>

<main>
  <section *ngIf="viewingTransactions">
    <div class="px-4 py-6 sm:px-0">
      <div class="w-full flex items-center justify-center">
        <div class="py-6 w-full">
          <div class="flex items-start justify-between">
            <div>
              <h2 class="uppercase font-semibold leading-6 text-lg text-remit-blue-900">
                Device transactions
              </h2>
              <p class="mt-1 text-sm leading-5 text-gray-600">
                Transactions that registered devices have executed
              </p>
            </div>
          </div>
          <div *ngIf="!hasDevices" class="mt-10">
            <div class="flex flex-col items-center justify-center">
              <svg class="w-16 h-16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                  clip-rule="evenodd" />
              </svg>
              <div class="text-center">
                No device transactions to show here. No devices have been registered under this account, yet.
                <button type="button" (click)="addDevices()" class="underline">Add devices</button> first and transact
                to show
                transactions
                here.
              </div>
            </div>
          </div>
          <div *ngIf="hasDevices">
            <form [formGroup]="filterForm" class="text-right">
              <div class="mt-4 w-full max-w-2xl inline-grid grid-cols-3 gap-2">
                <div class="relative text-left">
                  <label for="search-by" class="block text-sm font-medium leading-5 text-gray-700">Select
                    device</label>
                  <svg class="w-5 h-5 absolute z-10 right-0 mx-2 my-3 text-gray-500" xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd"
                      d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                      clip-rule="evenodd" />
                  </svg>
                  <select id="search-by" class="mt-1 py-2" #device (change)="filterTransactions(0)">
                    <option [value]="null" disabled hidden>{{devices[0].imei}}</option>
                    <option *ngFor="let device of devices" [value]="device.imei">{{device.imei}}</option>
                  </select>
                </div>
                <div class="text-left">
                  <label for="search_parameter" class="block text-sm font-medium leading-5 text-gray-700">
                    Start date</label>
                  <input id="search_parameter" type="date" [max]="maxDate > endDate ? endDate : maxDate"
                    (change)="filterTransactions(0)" class="mt-1 py-2" formControlName="startDate">
                </div>
                <div class="text-left">
                  <label for="search_parameter" class="block text-sm font-medium leading-5 text-gray-700">
                    End date</label>
                  <input id="search_parameter" type="date" [min]="startDate" [max]="maxDate"
                    (change)="filterTransactions(0)" class="mt-1 py-2" formControlName="endDate">
                </div>
                <!-- <div class="relative text-left">
                      <label for="search-by" class="block text-sm font-medium leading-5 text-gray-700">Search by</label>
                      <svg class="w-5 h-5 absolute z-10 right-0 mx-2 my-3 text-gray-500"
                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd"
                          d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                          clip-rule="evenodd" />
                      </svg>
                      <select id="search-by" class="mt-1 py-2">
                        <option value="id">Reference</option>
                        <option value="transactionDate">Date</option>
                        <option value="source">Source Acc/No.</option>
                        <option value="destination">Destination Acc/No.</option>
                        <option value="transactionType">Type</option>
                        <option value="operatorName">Operator</option>
                        <option value="transactionAmount">Amount</option>
                        <option value="typeOfEntry">DR/CR</option>
                        <option value="status">Status</option>
                      </select>
                    </div>
                    <div class="text-left">
                      <label for="search_parameter" class="block text-sm font-medium leading-5 text-gray-700">
                        Search key</label>
                      <div class="mt-1 relative rounded-sm shadow-sm">
                        <input id="search_parameter" type="text" class="mt-1 py-2">
                        <div class="absolute inset-y-0 right-0 flex items-center">
                          <button type="submit" class="px-2 focus:outline-none">
                            <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                              stroke="currentColor">
                              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div> -->
              </div>
            </form>
            <div *ngIf="!hasTransactions" class="mt-10">
              <div class="flex flex-col items-center justify-center">
                <svg class="w-16 h-16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                    clip-rule="evenodd" />
                </svg>
                <div class="text-center">
                  This device has no transactions for the period between {{startDate | date}} and {{endDate | date}}
                </div>
              </div>
            </div>
            <div *ngIf="hasTransactions" class="mt-2 flex flex-col">
              <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                  <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-sm">
                    <table class="min-w-full divide-y divide-gray-200">
                      <thead>
                        <tr>
                          <th
                            class="px-6 py-3 bg-cool-gray-900 text-left text-xs leading-4 font-medium text-white uppercase tracking-wider">
                            Transaction ID
                          </th>
                          <th
                            class="px-6 py-3 bg-cool-gray-900 text-left text-xs leading-4 font-medium text-white uppercase tracking-wider">
                            Date
                          </th>
                          <th
                            class="px-6 py-3 bg-cool-gray-900 text-left text-xs leading-4 font-medium text-white uppercase tracking-wider">
                            Source Acc/No.
                          </th>
                          <th
                            class="px-6 py-3 bg-cool-gray-900 text-left text-xs leading-4 font-medium text-white uppercase tracking-wider">
                            Destination Acc/No.
                          </th>
                          <th
                            class="px-6 py-3 bg-cool-gray-900 text-left text-xs leading-4 font-medium text-white uppercase tracking-wider">
                            Type
                          </th>
                          <th
                            class="px-6 py-3 bg-cool-gray-900 text-left text-xs leading-4 font-medium text-white uppercase tracking-wider">
                            Operator
                          </th>
                          <th
                            class="px-6 py-3 bg-cool-gray-900 text-left text-xs leading-4 font-medium text-white uppercase tracking-wider">
                            Amount
                          </th>
                          <th
                            class="px-6 py-3 bg-cool-gray-900 text-left text-xs leading-4 font-medium text-white uppercase tracking-wider">
                            DR/CR
                          </th>
                          <th
                            class="px-6 py-3 bg-cool-gray-900 text-left text-xs leading-4 font-medium text-white uppercase tracking-wider">
                            Status
                          </th>
                          <th class="px-6 py-3 bg-cool-gray-900"></th>
                        </tr>
                      </thead>
                      <tbody class="bg-white divide-y divide-gray-200">
                        <tr
                          *ngFor="let t of transactions | paginate: { id: 'transactions', itemsPerPage: 10, currentPage: pageNo, totalItems: totalTransactions }">
                          <td class="px-6 py-4 whitespace-no-wrap">
                            <div class="text-sm leading-5 font-medium text-gray-900">{{t.id}}</div>
                          </td>
                          <td class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                            <div class="text-sm leading-5 text-gray-500">{{t.transactionDate | date}}</div>
                          </td>
                          <td class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                            <div class="text-sm leading-5 text-gray-500">{{t.source}}</div>
                          </td>
                          <td class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                            <div class="text-sm leading-5 text-gray-500">{{t.destination}}</div>
                          </td>
                          <td class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                            <div class="text-sm leading-5 text-gray-500">{{t.transactionType}}</div>
                          </td>
                          <td class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                            <div class="text-sm leading-5 text-gray-500">{{t.operatorName}}</div>
                          </td>
                          <td class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                            <div class="text-sm leading-5 text-gray-500">
                              {{t.transactionAmount | currency:'NGN ':'symbol':'1.2-2'}}
                            </div>
                          </td>
                          <td class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-500">
                            <div class="text-sm leading-5 text-gray-500">{{t.typeOfEntry}}</div>
                          </td>
                          <td class="px-6 py-4 whitespace-no-wrap">
                            <span
                              class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                              {{t.status}}
                            </span>
                          </td>
                          <td class="px-6 py-4 whitespace-no-wrap text-right text-sm leading-5 font-medium">
                            <button (click)="fsDialog=true; viewTransaction=true; transaction=t"
                              class="ml-4 text-gray-700 hover:text-gray-900">View</button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="pt-4 flex items-center justify-center">
                      <pagination-controls class="paginator" id="transactions" (pageChange)="changePage($event)">
                      </pagination-controls>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div *ngIf="fsDialog" class="absolute z-10 inset-0 overflow-y-auto">
    <div class="fixed inset-0 transition-opacity">
      <div class="absolute inset-0 bg-gray-500 opacity-75">
      </div>
      <button (click)="fsDialog=false"
        class="absolute mt-20 mr-8 right-0 top-0 p-2 bg-white rounded-full cursor-pointer focus:outline-none">
        <svg class="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>
    </div>
    <div class="h-full flex items-center justify-center">
      <div
        class="w-full max-w-screen-lg bg-white rounded-sm text-left overflow-hidden shadow-xl transform transition-all sm:my-8"
        role="dialog" aria-modal="true" aria-labelledby="modal-headline">
        <div>
          <div class="bg-white shadow overflow-hidden sm:rounded-lg">
            <div class="px-4 py-5 border-b border-gray-200 sm:px-6">
              <h3 class="text-lg leading-6 font-medium text-gray-900">
                Transaction Information
              </h3>
              <p class="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
                Full transaction details.
              </p>
            </div>
            <div class="grid grid-cols-2">
              <div>
                <dl>
                  <div class="grid grid-cols-2 gap-8 bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-2 sm:gap-4 sm:px-6">
                    <dt class="text-sm leading-5 font-medium text-gray-500">
                      Date
                    </dt>
                    <dd class="ml-4 text-sm leading-5 text-gray-900 sm:mt-0">
                      <span *ngIf="!transaction.transactionDate">N/A</span>
                      <span *ngIf="transaction.transactionDate">{{transaction.transactionDate | date: 'medium'}}</span>
                    </dd>
                  </div>
                  <div class="grid grid-cols-2 gap-8 bg-white px-4 py-5 sm:grid sm:grid-cols-2 sm:gap-4 sm:px-6">
                    <dt class="text-sm leading-5 font-medium text-gray-500">
                      Transaction ID
                    </dt>
                    <dd class="ml-4 text-sm leading-5 text-gray-900 sm:mt-0">
                      <span *ngIf="!transaction.id">N/A</span>
                      <span *ngIf="transaction.id">{{transaction.id}}</span>
                    </dd>
                  </div>
                  <div class="grid grid-cols-2 gap-8 bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-2 sm:gap-4 sm:px-6">
                    <dt class="text-sm leading-5 font-medium text-gray-500">
                      Bank
                    </dt>
                    <dd class="ml-4 text-sm leading-5 text-gray-900 sm:mt-0">
                      <span *ngIf="!transaction.bankName">N/A</span>
                      <span *ngIf="transaction.bankName">{{transaction.bankName}}</span>
                    </dd>
                  </div>
                  <div class="grid grid-cols-2 gap-8 bg-white px-4 py-5 sm:grid sm:grid-cols-2 sm:gap-4 sm:px-6">
                    <dt class="text-sm leading-5 font-medium text-gray-500">
                      Destination Acc/No.
                    </dt>
                    <dd class="ml-4 text-sm leading-5 text-gray-900 sm:mt-0">
                      <span *ngIf="!transaction.destination">N/A</span>
                      <span *ngIf="transaction.destination">{{transaction.destination}}</span>
                    </dd>
                  </div>
                  <div class="grid grid-cols-2 gap-8 bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-2 sm:gap-4 sm:px-6">
                    <dt class="text-sm leading-5 font-medium text-gray-500">
                      Source Acc/No.
                    </dt>
                    <dd class="ml-4 text-sm leading-5 text-gray-900 sm:mt-0">
                      <span *ngIf="!transaction.source">N/A</span>
                      <span *ngIf="transaction.source">{{transaction.source}}</span>
                    </dd>
                  </div>
                  <div class="grid grid-cols-2 gap-8 bg-white px-4 py-5 sm:grid sm:grid-cols-2 sm:gap-4 sm:px-6">
                    <dt class="text-sm leading-5 font-medium text-gray-500">
                      Amount
                    </dt>
                    <dd class="ml-4 text-sm leading-5 text-gray-900 sm:mt-0">
                      <span *ngIf="!transaction.transactionAmount">N/A</span>
                      <span *ngIf="transaction.transactionAmount">
                        {{transaction.transactionAmount | currency:'NGN ':'symbol':'1.2-2'}}
                      </span>
                    </dd>
                  </div>
                  <div class="grid grid-cols-2 gap-8 bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-2 sm:gap-4 sm:px-6">
                    <dt class="text-sm leading-5 font-medium text-gray-500">
                      DR/CR
                    </dt>
                    <dd class="ml-4 text-sm leading-5 text-gray-900 sm:mt-0">
                      <span *ngIf="!transaction.typeOfEntry">N/A</span>
                      <span *ngIf="transaction.typeOfEntry">{{transaction.typeOfEntry}}</span>
                    </dd>
                  </div>
                  <div class="grid grid-cols-2 gap-8 bg-white px-4 py-5 sm:grid sm:grid-cols-2 sm:gap-4 sm:px-6">
                    <dt class="text-sm leading-5 font-medium text-gray-500">
                      Commission
                    </dt>
                    <dd class="ml-4 text-sm leading-5 text-gray-900 sm:mt-0">
                      <span *ngIf="!transaction.commission">N/A</span>
                      <span *ngIf="transaction.commission">
                        {{transaction.commission | currency:'NGN ':'symbol':'1.2-2'}}
                      </span>
                    </dd>
                  </div>
                  <div class="grid grid-cols-2 gap-8 bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-2 sm:gap-4 sm:px-6">
                    <dt class="text-sm leading-5 font-medium text-gray-500">
                      Transaction type
                    </dt>
                    <dd class="ml-4 text-sm leading-5 text-gray-900 sm:mt-0">
                      <span *ngIf="!transaction.transactionType">N/A</span>
                      <span *ngIf="transaction.transactionType">{{transaction.transactionType}}</span>
                    </dd>
                  </div>
                  <div class="grid grid-cols-2 gap-8 bg-white px-4 py-5 sm:grid sm:grid-cols-2 sm:gap-4 sm:px-6">
                    <dt class="text-sm leading-5 font-medium text-gray-500">
                      Status
                    </dt>
                    <dd class="ml-4 text-sm leading-5 text-gray-900 sm:mt-0">
                      <span *ngIf="!transaction.status">N/A</span>
                      <span *ngIf="transaction.status">{{transaction.status}}</span>
                    </dd>
                  </div>
                </dl>
              </div>
              <div>
                <dl>
                  <div class="grid grid-cols-2 gap-8 bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-2 sm:gap-4 sm:px-6">
                    <dt class="text-sm leading-5 font-medium text-gray-500">
                      Agent
                    </dt>
                    <dd class="ml-4 text-sm leading-5 text-gray-900 sm:mt-0">
                      <span *ngIf="!transaction.agentName">N/A</span>
                      <span *ngIf="transaction.agentName">{{transaction.agentName}}</span>
                    </dd>
                  </div>
                  <div class="grid grid-cols-2 gap-8 bg-white px-4 py-5 sm:grid sm:grid-cols-2 sm:gap-4 sm:px-6">
                    <dt class="text-sm leading-5 font-medium text-gray-500">
                      Agent number
                    </dt>
                    <dd class="ml-4 text-sm leading-5 text-gray-900 sm:mt-0">
                      <span *ngIf="!transaction.mobile">N/A</span>
                      <span *ngIf="transaction.mobile">{{transaction.mobile}}</span>
                    </dd>
                  </div>
                  <div class="grid grid-cols-2 gap-8 bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-2 sm:gap-4 sm:px-6">
                    <dt class="text-sm leading-5 font-medium text-gray-500">
                      Operator
                    </dt>
                    <dd class="ml-4 text-sm leading-5 text-gray-900 sm:mt-0">
                      <span *ngIf="!transaction.operatorName">N/A</span>
                      <span *ngIf="transaction.operatorName">{{transaction.operatorName}}</span>
                    </dd>
                  </div>
                  <div class="grid grid-cols-2 gap-8 bg-white px-4 py-5 sm:grid sm:grid-cols-2 sm:gap-4 sm:px-6">
                    <dt class="text-sm leading-5 font-medium text-gray-500">
                      Channel
                    </dt>
                    <dd class="ml-4 text-sm leading-5 text-gray-900 sm:mt-0">
                      <span *ngIf="!transaction.channel">N/A</span>
                      <span *ngIf="transaction.channel">{{transaction.channel}}</span>
                    </dd>
                  </div>
                  <div class="grid grid-cols-2 gap-8 bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-2 sm:gap-4 sm:px-6">
                    <dt class="text-sm leading-5 font-medium text-gray-500">
                      Description
                    </dt>
                    <dd class="ml-4 text-sm leading-5 text-gray-900 sm:mt-0">
                      <span *ngIf="!transaction.description">N/A</span>
                      <span *ngIf="transaction.description">{{transaction.description}}</span>
                    </dd>
                  </div>
                  <div class="grid grid-cols-2 gap-8 bg-white px-4 py-5 sm:grid sm:grid-cols-2 sm:gap-4 sm:px-6">
                    <dt class="text-sm leading-5 font-medium text-gray-500">
                      Fee
                    </dt>
                    <dd class="ml-4 text-sm leading-5 text-gray-900 sm:mt-0">
                      <span *ngIf="!transaction.fee">N/A</span>
                      <span *ngIf="transaction.fee">
                        {{transaction.fee | currency:'NGN ':'symbol':'1.2-2'}}
                      </span>
                    </dd>
                  </div>
                  <div class="grid grid-cols-2 gap-8 bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-2 sm:gap-4 sm:px-6">
                    <dt class="text-sm leading-5 font-medium text-gray-500">
                      Device IMEI
                    </dt>
                    <dd class="ml-4 text-sm leading-5 text-gray-900 sm:mt-0">
                      <span *ngIf="!transaction.imei">N/A</span>
                      <span *ngIf="transaction.imei">{{transaction.imei}}</span>
                    </dd>
                  </div>
                  <div class="grid grid-cols-2 gap-8 bg-white px-4 py-5 sm:grid sm:grid-cols-2 sm:gap-4 sm:px-6">
                    <dt class="text-sm leading-5 font-medium text-gray-500">&ndash;
                    </dt>
                    <dd class="ml-4 text-sm leading-5 text-gray-900 sm:mt-0">
                    </dd>
                  </div>
                  <div class="grid grid-cols-2 gap-8 bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-2 sm:gap-4 sm:px-6">
                    <dt class="text-sm leading-5 font-medium text-gray-500">&ndash;
                    </dt>
                    <dd class="ml-4 text-sm leading-5 text-gray-900 sm:mt-0">
                    </dd>
                  </div>
                  <div class="grid grid-cols-2 gap-8 bg-white px-4 py-5 sm:grid sm:grid-cols-2 sm:gap-4 sm:px-6">
                    <dt class="text-sm leading-5 font-medium text-gray-500">&ndash;
                    </dt>
                    <dd class="ml-4 text-sm leading-5 text-gray-900 sm:mt-0">
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>