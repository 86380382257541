<main class="h-screen flex flex-col">
  <div class="flex-1 flex overflow-hidden">
    <aside
      class="px-2 py-4 min-w-max-content w-72 bg-cool-gray-900 z-30 fixed inset-y-0 left-0 border-r overflow-y-auto sm:static sm:block sm:translate-x-0 sm:transition-none -translate-x-full ease-in transition-medium">
      <div class="h-16 px-4 -mx-2">
        <img src="../../../assets/images/metbank-logo.png" style="width: 85%;" alt="Logo">
      </div>
      <div class="mt-4 flex flex-col">
        <div class="flex flex-col space-y-4">
          <div class="space-y-1">
            <h6 class="text-xs text-cool-gray-500 font-bold uppercase tracking-wider">Dashboard</h6>
            <a [routerLink]="['dashboard', 'overview']" routerLinkActive="active-route" class="route">
              <svg class="w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path
                  d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
              </svg>
              <span class="ml-2">Overview</span>
            </a>
            <a *ngIf="rauth" [routerLink]="['dashboard', 'reps', 'operators']" routerLinkActive="active-route"
              class="route">
              <svg class="w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path
                  d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z" />
              </svg>
              <span class="ml-2">Operators</span>
            </a>
            <a *ngIf="rauth" [routerLink]="['dashboard', 'reps', 'devices']" routerLinkActive="active-route"
              class="route">
              <svg class="w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd"
                  d="M7 2a2 2 0 00-2 2v12a2 2 0 002 2h6a2 2 0 002-2V4a2 2 0 00-2-2H7zm3 14a1 1 0 100-2 1 1 0 000 2z"
                  clip-rule="evenodd" />
              </svg>
              <span class="ml-2">Devices</span>
            </a>
          </div>
          <div *ngIf="!rauth" class="space-y-1">
            <h6 class="text-xs text-cool-gray-500 font-bold uppercase tracking-wider">Transactions</h6>
            <a [routerLink]="['transactions', 'cash-in']" routerLinkActive="active-route" class="route">
              <svg class="w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd"
                  d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                  clip-rule="evenodd" />
              </svg>
              <span class="ml-2">Cash In</span>
            </a>
            <a [routerLink]="['transactions', 'cash-out']" routerLinkActive="active-route" class="route">
              <svg class="w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd"
                  d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM6.293 6.707a1 1 0 010-1.414l3-3a1 1 0 011.414 0l3 3a1 1 0 01-1.414 1.414L11 5.414V13a1 1 0 11-2 0V5.414L7.707 6.707a1 1 0 01-1.414 0z"
                  clip-rule="evenodd" />
              </svg>
              <span class="ml-2">Cash Out</span>
            </a>
            <a [routerLink]="['transactions', 'agent-to-agent']" routerLinkActive="active-route" class="route">
              <svg class="w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path
                  d="M8 5a1 1 0 100 2h5.586l-1.293 1.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L13.586 5H8zM12 15a1 1 0 100-2H6.414l1.293-1.293a1 1 0 10-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L6.414 15H12z" />
              </svg>
              <span class="ml-2">Agent to Agent</span>
            </a>
            <a [routerLink]="['transactions', 'wallet-to-bank']" routerLinkActive="active-route" class="route">
              <svg class="w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd"
                  d="M4 4a2 2 0 012-2h8a2 2 0 012 2v12a1 1 0 110 2h-3a1 1 0 01-1-1v-2a1 1 0 00-1-1H9a1 1 0 00-1 1v2a1 1 0 01-1 1H4a1 1 0 110-2V4zm3 1h2v2H7V5zm2 4H7v2h2V9zm2-4h2v2h-2V5zm2 4h-2v2h2V9z"
                  clip-rule="evenodd" />
              </svg>
              <span class="ml-2">Wallet to Bank</span>
            </a>
          </div>
          <div class="space-y-1">
            <h6 class="text-xs text-cool-gray-500 font-bold uppercase tracking-wider">Reports</h6>
            <a [routerLink]="['account', 'reports', 'transactions', 'operator']" routerLinkActive="active-route"
              class="route">
              <svg class="w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path
                  d="M2 11a1 1 0 011-1h2a1 1 0 011 1v5a1 1 0 01-1 1H3a1 1 0 01-1-1v-5zM8 7a1 1 0 011-1h2a1 1 0 011 1v9a1 1 0 01-1 1H9a1 1 0 01-1-1V7zM14 4a1 1 0 011-1h2a1 1 0 011 1v12a1 1 0 01-1 1h-2a1 1 0 01-1-1V4z" />
              </svg>
              <span class="ml-2">Operator Transactions</span>
            </a>
            <a [routerLink]="['account', 'reports', 'transactions', 'device']" routerLinkActive="active-route"
              class="route">
              <svg class="w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd"
                  d="M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V4a2 2 0 00-2-2H6zm1 2a1 1 0 000 2h6a1 1 0 100-2H7zm6 7a1 1 0 011 1v3a1 1 0 11-2 0v-3a1 1 0 011-1zm-3 3a1 1 0 100 2h.01a1 1 0 100-2H10zm-4 1a1 1 0 011-1h.01a1 1 0 110 2H7a1 1 0 01-1-1zm1-4a1 1 0 100 2h.01a1 1 0 100-2H7zm2 1a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1zm4-4a1 1 0 100 2h.01a1 1 0 100-2H13zM9 9a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1zM7 8a1 1 0 000 2h.01a1 1 0 000-2H7z"
                  clip-rule="evenodd" />
              </svg>
              <span class="ml-2">Device Transactions</span>
            </a>
          </div>
        </div>
        <div class="absolute -mx-2 pb-4 min-w-max-content w-72 space-y-1 bottom-0">
          <a
            class="flex items-center justify-between px-3 py-2 rounded-md text-sm font-medium text-white hover:text-white hover:bg-remit-blue-900 focus:outline-none focus:text-white focus:bg-remit-blue-900">
            <div class="flex items-center">
              <img class="w-8 h-8 rounded-full"
                src="https://cdn.dribbble.com/users/365424/avatars/normal/3555cfbd49e304d592daf761d121d4be.png?1535560970&compress=1&resize=40x40"
                alt="Memphis">
              <span class="ml-2">{{name}}</span>
            </div>
            <button (click)="logout()">
              <svg class="w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd"
                  d="M3 3a1 1 0 00-1 1v12a1 1 0 102 0V4a1 1 0 00-1-1zm10.293 9.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L14.586 9H7a1 1 0 100 2h7.586l-1.293 1.293z"
                  clip-rule="evenodd" />
              </svg>
            </button>
          </a>
          <a [routerLink]="['account', 'settings']" routerLinkActive="active-route"
            class="flex items-center ml-2 px-3 py-3 rounded-sm text-sm font-medium text-cool-gray-300 hover:bg-gray-800 focus:outline-none focus:bg-gray-800">
            <svg class="w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd"
                d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z"
                clip-rule="evenodd" />
            </svg>
            <span class="ml-2">Change password</span>
          </a>
        </div>
      </div>
    </aside>
    <div class="flex-grow flex">
      <div class="flex flex-col z-10 w-full flex-grow flex-shrink-0">
        <div class="flex-1 flex flex-col relative overflow-y-auto">
          <div class="bg-white flex-grow">
            <router-outlet></router-outlet>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>