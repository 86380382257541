<section>
  <app-processing-modal [process]="processing"></app-processing-modal>
  <app-error-modal [open]="error" [message]="aMessage"></app-error-modal>
  <app-warning-modal [open]="warning" [message]="aMessage"></app-warning-modal>
  <app-success-modal [open]="success" [message]="aMessage"></app-success-modal>
</section>

<main>
  <div class="py-6 px-8">
    <h1 class="text-3xl font-bold leading-tight text-gray-900">
      Settings
    </h1>
    <section class="max-screen-w-lg mx-auto">
      <div>
        <div class="w-full flex items-center justify-center">
          <div class="py-6 w-full max-w-screen-lg">
            <div>
              <h2 class="uppercase font-semibold leading-6 text-lg">
                Manage password
              </h2>
            </div>
            <form [formGroup]="changePasswordForm" (ngSubmit)="changePassword()">
              <div class="mt-8">
                <div>
                  <div>
                    <h3 class="text-lg font-medium leading-6 text-gray-900">Change password</h3>
                    <p class="mt-1 text-sm leading-5 text-gray-600">
                      Reset your current password to a new password
                    </p>
                  </div>
                  <div class="mt-5 w-full">
                    <form [formGroup]="changePasswordForm" (submit)="changePassword()">
                      <div class="shadow overflow-hidden rounded-sm">
                        <div class="p-6 bg-white">
                          <div class="grid grid-cols-2">
                            <div class="col-span-1 space-y-6">
                              <div *ngIf="!rauth">
                                <label for="username"
                                  class="block text-sm font-medium leading-5 text-gray-700">Username</label>
                                <input id="username" type="text" class="mt-1 py-2" formControlName="username">
                              </div>
                              <div class="relative">
                                <label for="old-password" class="block text-sm font-medium leading-5 text-gray-700">Old
                                  password</label>
                                <svg *ngIf="showPassword" (click)="showPassword=!showPassword"
                                  class="w-5 h-5 absolute z-20 right-0 mx-2 my-3 text-gray-500 cursor-pointer"
                                  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                  <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                                  <path fill-rule="evenodd"
                                    d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                                    clip-rule="evenodd" />
                                </svg>
                                <svg *ngIf="!showPassword" (click)="showPassword=!showPassword"
                                  class="w-5 h-5 absolute z-20 right-0 mx-2 my-3 text-gray-500 cursor-pointer"
                                  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                  <path fill-rule="evenodd"
                                    d="M3.707 2.293a1 1 0 00-1.414 1.414l14 14a1 1 0 001.414-1.414l-1.473-1.473A10.014 10.014 0 0019.542 10C18.268 5.943 14.478 3 10 3a9.958 9.958 0 00-4.512 1.074l-1.78-1.781zm4.261 4.26l1.514 1.515a2.003 2.003 0 012.45 2.45l1.514 1.514a4 4 0 00-5.478-5.478z"
                                    clip-rule="evenodd" />
                                  <path
                                    d="M12.454 16.697L9.75 13.992a4 4 0 01-3.742-3.741L2.335 6.578A9.98 9.98 0 00.458 10c1.274 4.057 5.065 7 9.542 7 .847 0 1.669-.105 2.454-.303z" />
                                </svg>
                                <input id="old-password" [type]="showPassword ? 'text' : 'password'" class="mt-1 py-2"
                                  formControlName="oldPassword">
                              </div>
                              <div>
                                <label for="new-password" class="block text-sm font-medium leading-5 text-gray-700">New
                                  password</label>
                                <input id="new-password" type="password" class="mt-1 py-2"
                                  formControlName="newPassword">
                              </div>
                              <div>
                                <label for="matching-password"
                                  class="block text-sm font-medium leading-5 text-gray-700">Matching password</label>
                                <input id="matching-password" type="password" class="mt-1 py-2"
                                  formControlName="matchingPassword">
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="px-6 py-3 bg-gray-50 text-right">
                          <div class="inline-flex">
                            <button type="submit" [disabled]="!changePasswordForm.valid"
                              [class]="{'cursor-not-allowed': !changePasswordForm.valid}"
                              class="btn py-2 text-white bg-cool-gray-900 shadow hover:shadow-md">
                              Set new password
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>
</main>