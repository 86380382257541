<section>
  <app-processing-modal [process]="processing"></app-processing-modal>
  <app-error-modal [open]="error" [message]="aMessage"></app-error-modal>
  <app-warning-modal [open]="warning" [message]="aMessage"></app-warning-modal>
  <app-success-modal [open]="success" [message]="aMessage"></app-success-modal>
</section>

<main>
  <div class="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
    <div class="max-w-md w-full space-y-8">
      <div align="center">
        <img src="../../../assets/images/metbank-logo.png" alt="Logo">
        <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Reset account password
        </h2>
      </div>
      <form *ngIf="!resetting" [formGroup]="sendTokenForm" (ngSubmit)="onSendToken()" class="mt-8 space-y-6">
        <h3 class="mt-6 text-sm text-center text-gray-700">
          Enter account email address or phone number to send reset token.
        </h3>
        <div class="rounded-md shadow-sm space-y-1">
          <div>
            <label for="email-pna" class="sr-only"></label>
            <input id="email-pna" name="email-pna" type="text" class="py-3" placeholder="Email address/Phone number"
              formControlName="email">
          </div>
        </div>
        <div>
          <button type="submit" [disabled]="!sendTokenForm.valid" [class]="{'cursor-not-allowed': !sendTokenForm.valid}"
            class="btn py-4 text-white bg-cool-gray-900 shadow hover:shadow-md">
            Send token
          </button>
        </div>
        <div class="flex items-center justify-center">
          <div class="text-sm">
            <a routerLink="/login" class="font-medium text-cool-gray-600 hover:text-cool-gray-900">
              Login instead
            </a>
          </div>
        </div>
      </form>
      <form *ngIf="resetting" [formGroup]="resetPasswordForm" (ngSubmit)="onSavePassword()" class="mt-8 space-y-6">
        <h3 class="mt-6 text-sm text-center text-gray-700">
          Set a new password (at lesat 8 characters).
        </h3>
        <div class="rounded-md shadow-sm space-y-1">
          <div>
            <label for="password" class="sr-only"></label>
            <input id="password" type="password" class="py-3" placeholder="New password" formControlName="password">
          </div>
          <div>
            <label for="confirm-password" class="sr-only"></label>
            <input id="confirm-password" type="password" class="py-3" placeholder="Confirm password"
              formControlName="matchingPassword">
          </div>
        </div>
        <div>
          <button type="submit" [disabled]="!resetPasswordForm.valid"
            [class]="{'cursor-not-allowed': !resetPasswordForm.valid}"
            class="btn py-4 text-white bg-cool-gray-900 shadow hover:shadow-md">
            Save password
          </button>
        </div>
      </form>
    </div>
  </div>
</main>